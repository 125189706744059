interface Settings {
  URL: {
    api: string;
    socket: string;
    game: {
      [key: string]: string;
    };
  };
  languages: {
    [key: string]: string[];
  };
}

export const settings: Settings = {
  URL: {
    api: "https://api.360.f3k.tech/",
    socket: "https://socketio.360.f3k.tech",
    game: {
      crazy: getGameUrl("crazy.game.amaze360.nl"),
      sdg: getGameUrl("sdg.game.amaze360.nl"),
      fortune: getGameUrl("fortune.game.f3k.tech"),
    },
  },
  languages: {
    crazy: ["en-GB", "nl-NL"],
    sdg: ["en-GB", "nl-NL", "hu-HU", "tr-TR"],
    fortune: ["en-GB", "nl-NL"],
  },
};

function getGameUrl(defaultValue: string): string {
  const urlParams = new URLSearchParams(window.location.search);
  const reviewValue = urlParams.get('review');

  if (reviewValue) {
    return `${reviewValue}.amaze360.nl`;
  }
  return defaultValue;
}
