<template>
  <div ref="jitsiContainer" style="height: 100%; width: 100%;"></div>
</template>

<script>
export default {
  props: {
    domain: {
      type: String,
      default: 'jitsi.vapybjv6s2ddf.360.f3k.tech'
    },
    options: {
      type: Object,
      default: () => ({
        roomName: sessionStorage.getItem('roomName'),
        userInfo: {
          displayName: sessionStorage.getItem('nickName'),
        },
        /* width: "300px",
        height: "31.25em", */
        parentNode: document.querySelector('#jitsi'),
        interfaceConfigOverwrite: {
          TOOLBAR_BUTTONS: [
            'microphone', 'camera', 'invite', 'tileview', 'chat'
            //'closedcaptions', 'embedmeeting', 'fullscreen',
            //'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
            //'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
            //'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
            //'tileview', 'select-background', 'download', 'help', 'mute-everyone', 'mute-video-everyone', 'security'
          ],
          SHOW_CHROME_EXTENSION_BANNER: false,
        },
        configOverwrite: {
          requireDisplayName: false,
          prejoinConfig: {
            enabled: false
          }
        }
      }),
    },
  },
  mounted() {
    this.loadScript('https://jitsi.vapybjv6s2ddf.360.f3k.tech/external_api.js', () => {
      if (!window.JitsiMeetExternalAPI) throw new Error('Jitsi Meet API not loaded');
      this.embedJitsiWidget();
    });
  },
  beforeUnmount() {
    this.removeJitsiWidget();
  },
  methods: {
    loadScript(src, cb) {
      const scriptEl = document.createElement('script');
      scriptEl.src = src;
      scriptEl.async = 1;
      document.querySelector('head').appendChild(scriptEl);
      scriptEl.addEventListener('load', cb);
    },
    embedJitsiWidget() {
      const options = {
        ...this.options,
        parentNode: this.$refs.jitsiContainer,
      };
      if (sessionStorage.getItem('roomName')) {
        this.jitsiApi = new window.JitsiMeetExternalAPI(this.domain, options);
      }
    },
    executeCommand(command, ...value) {
      this.jitsiApi.executeCommand(command, ...value);
    },
    addEventListener(event, fn) {
      this.jitsiApi.on(event, fn);
    },
    // Misc
    removeJitsiWidget() {
      if (this.jitsiApi) this.jitsiApi.dispose();
    },
  }
};
</script>